import {getCurrentInstance,reactive,toRefs,onMounted} from 'vue';
import contact from '../../../assets/contact.png';
export default {
    name: "about",
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        const dataObj=reactive({
            messageDrawer:false,
            aboutInfo:{corpNum:0,deptNum:0,personNum:0,onlineUserNum:0},
            contact:contact
        })
        onMounted(async ()=>{
            let res = await utils.Api.aboutInfo();
            if(res.result)dataObj.aboutInfo=JSON.parse(res.aboutInfo);
        })
        return{
            ...toRefs(dataObj)
        }
    }
};